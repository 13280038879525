@import "variables";
@import "mixins";

.popularLinks {
  background-color: $superLightGray;
  padding: 20px 0 50px;

  .container {
    @include pc() {
      padding: 0 25px;
    }

    @include sp() {
      padding: 0 20px;
    }

    @media screen and (min-width: 1160px) {
      width: 1160px;
      margin-left: calc(50% - 570px);
      padding: 0;
    }
  }

  .linkSection {
    margin: 0;
  }

  .linkSectionTitle {
    color: $black;
    font-size: 18px;
    font-weight: 600;
    margin: 32px 0 16px;
  }

  .categorySection {
    margin-bottom: 3px;

    @include sp {
      margin-bottom: 8px;
    }
  }

  .categorySectionTitle {
    display: inline-block;
    width: 60px;
    color: $black;
    font-size: 12px;
    font-size: 400;
  }

  .list {
    display: inline;
  }

  .listItem {
    display: inline-block;
    background-color: $white;
    border-radius: 3px;
    color: $darkGray2;
    font-size: 13px;
    font-size: 400;
    margin-bottom: 8px;
    padding: 6px 7px;

    &:not(:last-child) {
      margin-right: 7px;
    }

    &:hover {
      color: $blue;
    }
  }
}
