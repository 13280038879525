@import "variables";
@import "mixins";

//=======================================
// TOP > カテゴリー
//=======================================
.categoryContainer {
  background: $superLightGray;
  display: flex;
  justify-content: center;

  @include sp() {
    padding: 0 $sp-padding-width;
  }
}
.category {
  width: 1140px;

  h2 {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    margin-top: 43px;

    @include sp() {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 8px;
    }
  }

  p.description {
    margin-top: 5px;
    margin-bottom: 17px;

    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: $darkGray;

    @include sp() {
      margin-bottom: 14.82px;

      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: $darkGray2;
    }
  }
}

.categoryContent {
  display: flex;
  flex-wrap: wrap;
  @include sp() {
    justify-content: space-between;
    gap: 16px 10px;
    margin-bottom: 30px;
  }
  @include pc() {
    gap: 26.41px 20px;
    margin-bottom: 91px;
  }
}

//=======================================
// TOP > カテゴリー > アイテム
//=======================================
.categoryContentItem {
  position: relative;
  display: block;
  text-align: center;
  color: white;
  height: 160.59px;
  width: 270px;
  font-size: 20px;
  letter-spacing: 1px;

  @include sp() {
    height: 112px;
    width: calc((100% - 10px) / 2);
    font-size: 15px;
    border-radius: 4px;
  }

  a {
    display: block;
    height: calc(100% - 40.59px);
    border-radius: 5px;

    @include sp() {
      height: calc(100% - 35px);
      border-radius: 4px;
    }

    .imageWrap {
      position: relative;
      height: 100%;
    }

    .nameCard {
      height: 40.59px;
      width: 100%;
      padding: 10px 0 11px 13px;
      position: absolute;
      bottom: 1px;
      border-right: 1px solid $lightGray;
      border-left: 1px solid $lightGray;
      border-bottom: 1px solid $lightGray;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
      border-radius: 0 0 5px 5px;

      @include sp() {
        height: 35px;
        padding: 8px 0 7px 12.45px;
      }

      .nameCardText {
        text-align: left;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.03em;
      }
    }
  }

  img {
    border-radius: 5px 5px 0 0;
    z-index: 1;
  }
}
