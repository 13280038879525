@import "variables";
@import "mixins";

//===================================
// 共通部品 > サーベイコンテンツ
//===================================

.survey {
  padding-top: 30px;
  border-top: 1px solid $lightGray;

  .description {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $darkGray2;

    p {
      text-align: center;
    }
  }
}
.surveyContent {
  border-radius: 20px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  h2 {
    position: relative;
    display: flex;
    text-align: center;
    letter-spacing: 0.5px;
    span {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    @include sp {
      margin: 45px auto 30px !important;
      font-size: 18px;
      font-weight: 500;
    }
    @include pc {
      margin: 45px auto 20px !important;
      font-size: 25px;
      font-weight: 500;
    }
  }

  a {
    font-weight: 600;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }

  .contentWrap {
    @include typeformContentWrap;
  }
}
