@import "variables";
@import "mixins";

//===================================
// 共通部品 > 情熱コンテンツ
//===================================

.passionContainer {
  h2 {
    display: block;
    margin-bottom: 40px;
    text-align: center;
  }
}

// 想い
.passionContent {
  display: flex;
  margin: auto;

  @include pc {
    justify-content: center;
  }
  @include sp {
    flex-direction: column;
  }

  .kitoImage {
    position: relative;
    @include pc {
      width: 517px;
      height: 328px;
      margin-left: auto;
    }
    @include sp {
      width: 100%;
      height: 180px;
    }
  }
  .kitoPassion {
    @include pc {
      margin-left: 40px;
      font-size: 16px;
      line-height: 30px;
    }
    @include sp {
      margin-top: 25px;
      font-size: 14px;
      line-height: 28px;
    }
  }
}

.noteButton {
  display: flex;
  justify-content: center;
  gap: 5px;
  height: 50px;
  width: 230px;
  border-radius: 25px;
  margin: 45px auto 70px auto;
  background-color: #41c9b4;
  line-height: 50px;
  font-size: 15px;
  text-align: center;
  color: white;
  &:before {
    content: "";
    display: inline-block;
    width: 80px;
    height: 20px;
    background-image: url("/static/svgs/passion/note.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin: auto 0;
  }
}
