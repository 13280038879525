@import "variables";
@import "mixins";

.container {
  padding: 20px 20px 40px;
  background-color: $superLightGray;

  @include pc {
    padding: 27px 20px 54px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  overflow: hidden;

  @include pc {
    gap: 12px;
  }
}

.imageWrap {
  width: 78px;

  @include pc {
    width: 83px;
  }

  svg {
    margin-bottom: -22px;

    @include sp {
      scale: 0.85;
    }
  }
  @media (max-width: 360px) {
    display: none;
  }
}

.headerText {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-bottom: 8px;

  @include pc {
    font-size: 18px;
    margin-bottom: 12px;
  }
}

.mainContent {
  max-width: $pc-width;
  padding: 27px 17px 32px;
  background-color: $white;
  border-radius: 20px;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.071);

  @include pc {
    margin: auto;
    padding: 32px 17px 47px;
  }
  .catch {
    font-size: 20px;
    font-weight: 600;
    color: $blue;
    text-align: center;
    letter-spacing: 1.5px;

    @include pc {
      font-size: 27px;
    }
  }

  .buttonsWrap {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-top: 20px;

    @include pc {
      justify-content: center;
      flex-direction: row;
    }
  }

  .actionButton {
    height: 46px;
    max-width: 360px;
    font-size: 15px;
    color: $lightBlack;

    @include pc {
      height: 60px;
      font-size: 16px;
      margin: unset;
    }
  }
}

.subContentContainer {
  max-width: $pc-width;
  margin: auto;

  @include pc {
    margin: auto;
  }
  .description {
    margin: 16px 0 0;
    font-size: 12px;
    line-height: 21px;

    @include pc {
      margin: 26px auto 0;
      max-width: 689px;
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
    }
  }

  .subContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 25px;

    @include pc {
      flex-direction: row;
      gap: 20px;
      margin-top: 35px;
    }
    .bubble {
      position: relative;
      display: inline-block;
      border-radius: 20px;
      padding: 6px 12px;
      z-index: 1;
      background-color: $blue;
      font-size: 13px;
      line-height: 1.5;

      &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 25px;
        height: 14px;
        width: 14px;
        transform: rotate(125deg) skew(-20deg);
        z-index: -1;
        border-radius: 2px;
        background-color: $blue;
      }

      .bubbleText {
        color: $white;
      }
    }
    .descriptionBlock {
      flex: 1;
      @include sp {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .list {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;

        @include pc {
          gap: 16px;
          flex-direction: row;
        }

        .item {
          display: flex;
          flex: 1;
          align-items: center;
          gap: 8px;
          padding: 12px 10px;
          border: 1px solid $blue;
          border-radius: 5px;
          color: $blue;
          background-color: $white;

          @include pc {
            gap: 16px;
            max-width: 272px;
            padding: 12px 16px;
          }

          .withIconText {
            position: relative;
            margin-left: 28px;

            &::before {
              position: absolute;
              content: "";
              top: 50%;
              left: -26px;
              background-image: url("/static/svgs/common/one.svg");
              width: 17px;
              height: 21px;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
    .secondaryDescriptionBlock {
      @extend .descriptionBlock;

      .bubble {
        background-color: $gray;

        &::after {
          background-color: $gray;
        }

        .bubbleText {
          font-weight: 600;
          color: $black;
        }
      }

      .list {
        .item {
          border: 1px solid $lightGray;
          color: $black;
        }
      }
    }
    .descriptionBlock:nth-child(1) .list .item:nth-child(2) .withIconText::before {
      background-image: url("/static/svgs/common/two.svg");
    }
  }
}
