@import "variables";
@import "mixins";

.swiperItems {
  overflow: hidden;
  width: 100%;

  .slider {
    display: flex;
    width: 3417px; //SwiperLogos のSP用の画像3枚の合計の幅
    height: 40px;
    animation: slide 60s linear infinite;

    @include pc {
      width: 4516px; // SwiperLogos のPC用の画像3枚の合計の幅
      height: 60px;
    }
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
