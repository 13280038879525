@import "variables";
@import "mixins";

.topContainer {
  h2 {
    @include sp() {
      margin-top: 50px;
      margin-bottom: 15px;
      line-height: 1.5;
    }
    @include pc() {
      margin-bottom: 25px;
    }
  }
}

.insert {
  @include pc() {
    display: flex;
    justify-content: center;
    margin: 80px auto 0;
  }
  @include sp() {
    display: none;
  }

  .button {
    display: flex;
    justify-content: center;
    width: 300px;
    height: 80px;
    background-color: $blue;
    border-radius: 40px;
    color: white;
    font-size: 20px;
    line-height: 80px;
    text-align: center;
  }
  & > p {
    display: inline-block;
    margin-left: 30px;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 2px;
  }
  .searchSVG {
    display: inline-block;
    height: 25px;
    width: 25px;
    margin: auto 10px auto 0;
    background-image: url("/static/svgs/common/search_white.svg");
    background-position: center;
    background-size: contain;
  }
}

.carouselItem {
  @include pc() {
    width: 1140px;
    margin: -30px auto 30px auto;
  }
}
