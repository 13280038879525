@import "variables";
@import "mixins";

//=======================================
// TOP > 連携各社
//=======================================
.publicationAgentsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 91px 0;

  @include sp() {
    margin: 45px 0;
  }

  .catch {
    position: relative;
    color: $blue;
    font-weight: 600;

    @include sp() {
      font-size: 16px;
    }
    @include pc() {
      font-size: 20px;
    }
  }
  .catch:before,
  .catch:after {
    content: "";
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: $blue;
  }

  .catch:before {
    left: -20px;
    transform: rotate(-35deg);
  }

  .catch:after {
    right: -16px;
    transform: rotate(35deg);
  }

  .imageContainer {
    position: relative;
    border: 1px solid $blue;
    border-radius: 20px;

    @include sp() {
      padding: 37px 20px;
      margin-top: 20px;
    }
    @include pc() {
      padding: 80px 75px;
      margin-top: 40px;
    }
  }

  .title {
    position: absolute;
    top: -16px;
    left: 50%;
    padding: 0 25px;
    transform: translateX(-50%);
    color: $blue;
    background-color: $white;
    font-weight: 600;

    @include sp() {
      font-size: 18px;
    }
    @include pc() {
      font-size: 25px;
    }
  }

  .publicationAgents {
    display: block;
    height: 500px;
    width: 985px;

    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("/static/images/top-images/publication-agents.png");

    @include sp() {
      height: 261px;
      width: 295px;

      background-image: url("/static/images/top-images/publication-agents-sp.png");
    }
  }

  .cautionNote {
    position: absolute;
    color: $gray;

    @include sp() {
      bottom: 10px;
      right: 20px;
      font-size: 12px;
    }
    @include pc() {
      bottom: 20px;
      right: 27px;
      font-size: 14px;
    }
  }
}
