@import "variables";
@import "mixins";

/*******************************
 * Implementation of List page *
 *******************************/

.container {
  margin-bottom: 40px;
  @include pc() {
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
  @include sp() {
    padding: 0 10px;
  }
}

//===================================
// 一覧画面 > 検索タイトル
//===================================
.titleSection {
  font-weight: 300;

  @include pc() {
    h1 {
      margin-bottom: 12px;
      font-size: 25px;
      line-height: 46px;
    }
  }

  @include sp() {
    padding: 15px 0 10px;

    h1 {
      display: inline;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    span {
      font-size: 15px;
      line-height: 30px;
      span {
        color: $blue;
      }
    }
  }
}

.topBanner {
  @include sp {
    margin-bottom: 16px;
  }
}

.body {
  @include pc() {
    display: flex;
  }

  a {
    font-weight: 400;
  }
}

//===================================
// 一覧画面 > 検索条件を設定するSection
//===================================
.conditionSection {
  // PCはサイドバー、SPはモーダル
  @include pc() {
    width: 300px;
    margin-right: 50px;
    z-index: 1;
  }
  @include sp() {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100vw;
    height: calc(100% + 100px);
    padding: 0 0 100px;
    background-color: white;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 11;
    transition: 0.5s;

    &.isActive {
      right: 0;
    }

    .conditionSectionTitle {
      display: block;
      position: relative;
      margin: 30px 25px;
      padding-left: 7px;
      border-left: 2px solid $blue;
      line-height: 1;
      font-size: 16px;
      color: $blue;

      p {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        line-height: 16px !important;
        font-size: 20px !important;
      }
    }
  }

  p:not([class]) {
    line-height: 1.5;
    font-size: 12px;
  }

  .separator {
    display: block;
    width: 100%;
    border-top: 1px solid $lightGray;
    margin-top: 20px;
  }

  input,
  select {
    font-size: 12px;
  }
}

// PC版における、検索条件の区切り
.conditionSubSection {
  @include pc {
    border-radius: 5px;
    padding: 15px;
    background-color: $superLightGray;

    + .conditionSubSection {
      margin-top: 15px;
    }
  }
}

// 検索条件の名前
.conditionName {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  line-height: 1;
  @include pc {
    margin: 20px 0 15px;
    color: $blue;
    font-size: 14px;
  }
  @include sp {
    margin: 20px 20px 15px;
    color: $black;
    font-size: 14px;
  }
  .showMore {
    display: flex;
    margin: auto 0 auto auto;
    font-size: 11px;
    color: $blue;
    cursor: pointer;
    &:after {
      content: "";
      margin: auto 0 auto 1px;
      width: 7px;
      height: 7px;
      border-top: 1px solid $blue;
      border-right: 1px solid $blue;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

// 普通の検索条件
.defaultCondition {
  @include sp {
    padding: 0 20px;
  }

  // 2列になっている検索条件
  &.isTwoColumns {
    display: flex;
    justify-content: space-between;
    & > div {
      width: calc((100% - 10px) / 2);
    }
    > p {
      line-height: 40px !important;
      padding: 0 10px;
      color: $gray;
    }
  }
}

// checkboxが並んでいる検索条件
.checklistCondition {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -6px;

  @include sp {
    gap: 6px;
    padding: 0 20px;
    margin: 0 -2px; //白線分

    label {
      display: block;
      padding: 0 20px;
      border: 2px solid white; // 押下時にサイズ変更が発生しないよう白線を引く
      border-radius: 21px;
      line-height: 42px !important;
      background-color: $superLightGray2;
      font-size: 12px !important;
    }

    input[type="checkbox"] {
      & + label {
        &:before {
          content: none;
        }
      }
      &:checked + label {
        background-color: $superLightGray2;
        border: 2px solid $blue;
        color: $blue;
        &:before {
          content: none;
        }
      }
    }
  }

  @include pc {
    .checklistConditionItem {
      width: 100%;
      margin-top: 6px;
    }

    // 2列になっている検索条件
    &.isTwoColumns span {
      width: 50%;
    }
  }
}

.isSeparatorPC {
  @include pc {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $lightGray;
  }
}

.descriptionArea {
  padding: 10px 16px;
  margin: 0 20px 20px;
  border-radius: 5px;
  background-color: #e9f4fb;

  @include pc {
    margin: 0 0 20px;
  }

  .description {
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 500;
    color: $blue;
  }

  .hintMessageWrap {
    width: 120px;
    margin-top: 8px;
  }
}

.stickySP {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  @include sp {
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 25px 20px;
    background: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
  }
}

//===================================
// 一覧画面 > 検索条件を設定するSection
//===================================
.resultSection {
  width: 100%;
  flex: 1;

  @include sp {
    margin-top: 16px;
  }
}

// 検索結果のサマリ（条件・件数）
.resultSummary {
  display: flex;
  align-items: baseline;
  width: 100%;
  flex: 1;
  @include pc() {
    margin-bottom: 10px;
  }
  @include sp() {
    margin-bottom: 20px;
  }

  .resultSummaryEmphasizeText {
    color: $blue;
    font-weight: 400;
    @include pc() {
      font-size: 20px;
    }
    @include sp() {
      font-size: 15px;
    }
  }
}

.loadingText {
  margin: 8px 0 10px;
  @include sp() {
    font-size: 12px;
  }
}

.resultCountTextSP {
  @include pc {
    display: none;
  }
  @include sp {
    margin: 15px 0 5px;
    font-size: 12px;
    span {
      font-size: 14px;
      color: $blue;
    }
  }
}

.paginator {
  display: flex;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: row;
  font-size: 0;

  @include sp() {
    justify-content: center;
    margin-right: auto;
  }
  &.bottom {
    justify-content: center;
  }
}

.paginatorItem {
  position: relative;
  display: inline-block;
  height: 30px;
  min-width: 30px;
  border-radius: 3px;
  border: 1px solid $blue;
  color: $blue;
  line-height: 28px;
  text-align: center;
  transition: all 0.5s;
  &:not(:first-child) {
    margin-left: 4px;
  }

  &.isActive {
    background-color: $blue;
  }

  &.hidden {
    display: none;
  }
  &.hidden + :not(.hidden) {
    margin-left: 30px !important;
    &:before {
      position: absolute;
      bottom: -8px;
      font-size: 14px;
      left: -22px;
      content: "...";
    }
  }
}

.paginatorItemLink {
  display: inline-block;
  width: 100%;
  color: $blue;
  font-size: 14px;
  font-weight: 500;
  padding: 0 3px;
}
.paginatorItem.isActive .paginatorItemLink {
  color: $white;
}

.conditionButton {
  width: 100%;
  border-radius: 5px;
  border: 1px solid $blue;
  line-height: 40px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;

  &.isFilled {
    background-color: $blue;
    color: white;
  }
  &.isOutlined {
    background-color: white;
    color: $black;
  }

  @include pc {
    margin-bottom: 20px;
  }
}

// FIXME: CurrentConditionSectionにあるパーツとの重複を解消する
.saveButton {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid $blue;
  padding: 0 10px;
  background-color: #fff;
  font-size: 12px;
  color: $blue;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px #2391dc33;

  svg {
    margin: auto 5px auto 0;
    stroke: white;
  }

  @include sp {
    line-height: 28px;
  }
  @include pc {
    width: 100%;
    line-height: 40px;
  }
}

.floorPlanBannerWrap {
  @include pc {
    border-radius: 10px;
    background-color: $superLightGray;
    margin-top: 14px;
    margin-bottom: 24px;
    padding: 16px 32px;
  }

  @include sp {
    padding: 12px 0 16px;
  }
}

.initialCostScorePullDownWrap {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .hintMessageWrap {
    margin-left: 10px;
  }

  @include sp {
    margin-bottom: 18px;
  }
}

.viewAvailableToggleWrap {
  margin: 14px 0 20px;
}

.panelList {
  @include sp {
    width: 100vw;
    margin-left: -10px;
  }
}

.noResultMessage {
  border: 2px solid $pink;
  border-radius: 5px;
  padding: 44px;
  margin-bottom: 24px;

  @include sp {
    padding: 24px 16px;
  }
}

.noResultMessageTitle {
  color: $pink;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;

  @include sp {
    font-size: 14px;
  }
}

.noResultMessageBody {
  max-width: 350px;
  font-size: 14px;
  line-height: 1.5;
  margin: 24px auto 0;

  @include sp {
    max-width: 276px;
    font-size: 11px;
  }
}

.newArrivedBuildingsWrap {
  @include pc {
    width: 1140px;
    margin: 40px auto 60px;
  }
}

.divider {
  height: 16px;
  background-color: $superLightGray2;
  width: 100vw;
  transform: translateX(-10px);
}

.sectionTitle {
  margin-bottom: 16px;

  @include sp() {
    margin-bottom: 10px;
    padding: 0 20px;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
}

.grayBox {
  background-color: $superLightGray;
  margin: 40px 0 0;
}

.grayBoxInner {
  padding: 30px 0;

  @include pc() {
    padding: 40px 0;
    width: 1140px;
    margin: auto;
  }
}

.note {
  color: $darkGray;

  &.isAlignRight {
    text-align: right;
  }
}

.horizontalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hintMessageWrap {
    margin-right: 20px;

    @include pc {
      margin-right: 0;
    }
  }
}

.hintMessageSource {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: $darkGray;
  text-decoration: underline;
  cursor: pointer;
}

.hintMessageSource::before {
  position: absolute;
  top: 0;
  left: -17px;
  width: 14px;
  height: 14px;
  background-image: url("/static/svgs/condition/question.svg");
  content: "";
}

.hintMessageSource:hover + .hintMessage {
  display: block;
}
.hintMessage {
  bottom: 32px;
  left: -175px;
  z-index: 200;
  border-radius: 10px;
  padding: 18px;

  @include pc {
    position: absolute;
    display: none;
    background-color: $white;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.14);
  }

  .activeStar {
    font-family: Hiragino Sans;
    color: $darkYellow2;
  }
  .inactiveStar {
    font-family: Hiragino Sans;
    color: $lightGray;
  }

  .mt6 {
    margin-top: 6px;
  }
}

.savedConditionModal {
  padding: 8px;
  text-align: center;

  .title {
    color: $blue;
    font-size: 16px;
    font-weight: 600;
  }

  .description {
    margin-top: 8px;
    font-size: 13px;
    color: $black;
  }
}

.airdoorBannerWrap {
  margin: 0 10px 20px;
  @include pc {
    margin: 0 0 40px;
  }
}

.errorBoundaryText {
  margin: 20px auto;
  text-align: center;
}

.stickyPC {
  position: sticky;
  bottom: 20px;
}

.searchButtonsWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 24px 14px 14px;
  background-color: $lightGray;
  border-radius: 5px;

  .buttonBase {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px;
    line-height: 1.5;
    border: none;

    @include pc {
      transition: opacity $hover-transition;
      &:hover {
        cursor: pointer;
        opacity: $hover-opacity;
      }
    }
  }

  .primaryButton {
    @extend .buttonBase;
    font-size: 14px;
    font-weight: 600;
    background-color: $blue;
    border-radius: 5px;
    color: $white;
  }

  .secondaryButton {
    @extend .buttonBase;
    font-size: 13px;
    font-weight: 500;
    background-color: transparent;
    color: $blue;
  }
}
