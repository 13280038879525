@import "variables";
@import "mixins";

.container {
  display: flex;
  width: 1140px;
  margin: 60px auto;

  background: linear-gradient(68.31deg, #0881d3 -5.84%, #85c1ea 100.96%, #ffffff 161.63%);
  border-radius: 20px;

  .phone {
    margin: 18px 0 0 80px;
  }

  .catchContainer {
    margin: auto 0 auto 30px;
  }

  .yellowText {
    font-size: 18px;
    color: $campaignYellow;
    letter-spacing: 1px;
  }

  .largeText {
    font-size: 30px;
    font-weight: 600;
    color: $white;
    letter-spacing: 1px;
  }
  .smallText {
    font-size: 18px;
    color: $white;
  }
  .cross {
    font-size: 28px;
    color: $white;
    letter-spacing: 5px;
  }
  .isFlex {
    display: flex;
    align-items: center;
  }

  .line {
    width: 1px;
    height: 180px;
    margin: auto 0 auto 40px;
    opacity: 0.5;
    background-color: #fff;
  }

  .appIcon {
    position: relative;
    width: 78px;
    height: 78px;
    margin-left: 40px;
  }

  .qr {
    margin-left: 8px;
  }
  .appLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    margin-left: 20px;

    a {
      transition: $hover-transition;
      &:hover {
        opacity: $hover-opacity;
      }
    }
    @include pc {
      gap: 9px;
      border-bottom: none;
    }
    .appStore {
      position: relative;
      width: 110px;
      height: 40px;
    }

    .googlePlay {
      position: relative;
      width: 110px;
      height: 32px;
    }
  }
}
