@import "variables";
@import "mixins";

.container {
  position: relative;
  width: 100%;
  display: flex;
  background-color: $white;
  border: 1px solid $lightGray;
}
.container.top {
  height: 50px;
  border-radius: 10px;

  @include sp {
    height: 40px;
    border-radius: 5px;
  }
}
.container.menu {
  height: 40px;
  border-radius: 3px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: textfield;

  // xボタンの非表示
  &::-webkit-search-cancel-button {
    appearance: none;
  }
}
.input.top {
  height: 48px;
  font-size: 16px;
  padding-left: 20px;

  @include sp {
    height: 38px;
    font-size: 13px;
    padding-left: 17px;
  }
}
.input.menu {
  height: 38px;
  font-size: 13px;
  padding-left: 15px;
}
.input::placeholder {
  color: $gray;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.button.top {
  padding: 2px 15px 0;

  @include sp {
    padding: 2px 10px 0;
  }
}
.button.menu {
  padding: 2px 10px 0;
}
