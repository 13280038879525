@import "variables";
@import "mixins";

.mediaContainer {
  .title {
    display: block;
    margin-bottom: 40px;
    text-align: center;

    @include sp {
      margin-top: 0;
    }
  }
}

.mediaList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include pc {
    gap: 40px;
  }
  @include sp {
    gap: 20px;
  }

  .mediaListItem {
    position: relative;
    display: block;
    @include pc {
      width: 200px;
      height: 60px;
    }
    @include sp {
      width: 100px;
      height: 28px;
    }
  }
}
