@import "variables";
@import "mixins";

//===================================
// 共通部品 > Q&Aコンテンツ
//===================================
.qaContainer {
  border-top: 1px solid $lightGray;
  margin-top: 55px;
}
.qaContent {
  h2 {
    position: relative;
    display: flex;
    text-align: center;
    letter-spacing: 0.5px;
    span {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 7em;
      margin: auto;
    }
    @include sp {
      margin: 45px auto 30px !important;
      font-size: 18px;
      font-weight: 500;
    }
    @include pc {
      margin: 45px auto 50px !important;
      font-size: 25px;
      font-weight: 500;
    }
  }
}

//===============================================
// 共通部品 > Q&Aコンテンツ > Q&Aリッチコンテンツ
//===============================================
.qaRichContents {
  display: flex;
  justify-content: space-between;

  @include sp {
    flex-direction: column;
  }
}

.qaRichContent {
  position: relative;

  @include sp() {
    &:first-child {
      margin-bottom: 36px;
    }
  }

  &Title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: $blue;
    font-weight: 500;
    text-align: center;
    color: $white;
    @include sp {
      width: 300px;
      border-radius: 23px;
      font-size: 15px;
      line-height: 46px;
    }
    @include pc {
      width: 408px;
      border-radius: 27px;
      font-size: 18px;
      line-height: 54px;
    }
    &:before {
      position: absolute;
      content: "";
      bottom: -11px;
      left: 0;
      right: 0;
      height: 0;
      width: 0;
      margin: auto;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid $blue;
    }
  }

  &Body {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgba(35, 145, 220, 0.05);
    border-radius: 10px;

    @include sp {
      width: 100%;
      margin-top: 23px; //Titleの高さ / 2
      padding: 60px 60px 25px;
    }
    @include pc {
      display: flex;
      width: 540px;
      height: 320px;
      margin-top: 27px; //Titleの高さ / 2
      padding: 65px 0;
    }

    ul {
      @include pc {
        margin: 0 auto;
      }
    }

    li {
      position: relative;
      color: $blue;
      font-weight: 500;
      @include sp {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 22px;
      }
      @include pc {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 30px;
      }

      &:before {
        content: "";
        position: absolute;
        transform: rotate(-45deg);

        @include sp {
          top: 7px;
          left: -19px;
          height: 8px;
          width: 12px;
          border-left: 3px solid $darkYellow;
          border-bottom: 3px solid $darkYellow;
        }
        @include pc {
          top: 10px;
          left: -22px;
          height: 9px;
          width: 14px;
          border-left: 4px solid $darkYellow;
          border-bottom: 4px solid $darkYellow;
        }
      }
    }
  }

  &Image {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    @include sp {
      margin: 10px auto 0;
    }
    @include pc {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 40px;
      margin: auto;
    }
    @for $i from 1 through 2 {
      &#{$i} {
        @include sp {
          background-image: url("/static/svgs/qa/richcontents#{$i}_sp.svg");
        }
        @include pc {
          background-image: url("/static/svgs/qa/richcontents#{$i}_pc.svg");
        }
      }
    }

    &1 {
      @include pc {
        width: 297px;
        height: 95px;
      }
      @include sp {
        width: 231px;
        height: 85px;
      }
    }
    &2 {
      width: 186px;
      height: 72px;
    }
  }
}

//===============================================
// 共通部品 > Q&Aコンテンツ > Q&Aの一覧
//===============================================
.qaList {
  margin: 83px 0 50px;
}

//===============================================
// 共通部品 > Q&Aコンテンツ > Q&Aの一覧 > 1つ1つのQ&A
//===============================================
.qaItem {
  width: 100%;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  font-size: 15px;
  cursor: pointer;
  + dl {
    border-top: none;
  }
  * {
    cursor: pointer;
  }

  dt {
    display: flex;
    padding-top: 10px;
    &:before {
      display: block;
      content: "";
      width: 60px;
      height: 40px;
      padding-bottom: 10px;
      background: no-repeat center url("/static/svgs/qa/q.svg");
    }
    p {
      width: 100%;
      flex: 1;
      line-height: 30px;
      padding: 5px 0 15px 30px;
      border-left: 1px solid $gray;
    }
  }
  dd {
    display: none;
    @include sp() {
      font-size: 12px;
    }
    &:before {
      background-repeat: no-repeat;
      background-position: center top 25px;
      background-image: url("/static/svgs/qa/a.svg");
    }
  }
  &.isOpened {
    dd {
      display: flex;
      border-top: 1px solid $gray;

      &:before {
        display: block;
        content: "";
        width: 60px;
      }
      p {
        width: 100%;
        flex: 1;
        border-left: 1px solid $gray;
        padding: 25px 0 25px 30px;
        line-height: 2;
        color: $blue;
        white-space: pre-line;
      }
    }
  }
}
