@import "variables";
@import "mixins";

$fontFamily-Top: "Hiragino Sans";

.gradationFont {
  background: $gradation-standard;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

//===================================
// TOP画面 > ヒーロー画像
//===================================
.heroContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("/static/images/top-images/background.png");
  background-size: cover;
  background-position: 50%;

  span {
    margin: auto !important;
  }
  img {
    pointer-events: none;
  }

  .logoMain {
    position: absolute;
    height: 147px;
    width: 282px;
    left: 58%;
    top: 140px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url("/static/images/top-images/logo-pc-1.png");

    @media screen and (max-width: 1299px) {
      display: none;
    }

    &:after {
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
      height: 271px;
      width: 269px;
      display: inline-block;
      margin-top: -13px;
      margin-left: 120px;
      background-image: url("/static/images/top-images/logo-pc-2.png");

      @include sp() {
        display: none;
      }
    }
  }

  .innerContainer {
    @include pc() {
      width: 1140px;
      margin: auto;
    }
    @include sp() {
      display: flex;
      flex-direction: column;
    }
  }

  .messageArea {
    margin-top: 40px;

    @include sp() {
      margin-left: 20px;
      margin-top: 20px;
    }

    .priorityMessage {
      p {
        font-family: $fontFamily-Top;
        font-style: normal;
        font-weight: 600 !important;
        font-size: 42px;
        line-height: 68px;

        @include sp() {
          font-size: 22px;
          line-height: 36px;
        }
      }

      span.gradation {
        @extend .gradationFont;
      }
    }
    .secondaryMessage {
      margin-top: 8px;
      font-size: 12px;
      line-height: 1.5;
      font-weight: 600;
      letter-spacing: 1px;

      @include pc {
        font-size: 15px;
      }
    }
  }
  .messageArea2 {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include sp() {
      margin-top: 0px;
      height: fit-content;
      margin-left: 0;
      display: flex;
      padding: 0 5%;
      justify-content: flex-start;
    }

    &__title {
      margin: 14px auto 4px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
    }

    &__item {
      min-width: 285px;
      height: 100%;
      margin-top: 16px;
      padding: 8px 20px 8px 14px;
      font-size: inherit;
      border-radius: 3px;
      background-color: #2391dc12;

      @include sp() {
        min-width: 100%;
        margin-top: 8px;
      }

      &:before {
        content: "";
        vertical-align: middle;
        height: 22px;
        width: 22px;
        display: inline-block;
        margin-right: 7px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;

        @include sp() {
          height: 16px;
          width: 16px;
        }
      }

      &:first-child() {
        margin-top: 0;
      }

      .text {
        vertical-align: middle;
        font-family: $fontFamily-Top;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 1px;
        color: #464646;

        @include sp() {
          font-size: 13px;
          line-height: 19.5px;
        }
      }

      .blackText {
        @extend .text;
        vertical-align: bottom;
      }

      .gradation {
        @extend .gradationFont;
        @extend .text;
        font-size: 20px;
        line-height: 1.5;

        @include sp() {
          font-size: 16px;
        }
      }

      &.clock:before {
        background-image: url("/static/svgs/top/clock.svg");
      }
      &.building:before {
        background-image: url("/static/svgs/top/feature4_pc.svg");
      }
      &.calc:before {
        background-image: url("/static/svgs/top/calc.svg");
      }
    }
  }

  .controlAreaWrapper {
    display: flex;
    gap: 28px;
    margin: 30px 0 54px 0;

    @include sp {
      flex-direction: column-reverse;
      gap: 16px;
      margin: 20px 0 16px;
    }
  }
  .controlArea {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include sp {
      gap: 16px;
      padding: 0 5%;
    }
  }
  .aboutLink {
    position: relative;
    padding: 10px 0;
    border: 1px solid $blue;
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);

    @include pc {
      display: flex;
      justify-content: center;
      width: 157px;
      height: 142px;
      margin-top: -50px;
      border: 2px solid $blue;
      cursor: pointer;
      transition: $hover-transition;

      &:hover {
        opacity: $hover-opacity;
      }
    }

    .innerWrap {
      display: flex;
      justify-content: center;
      align-items: center;

      @include pc {
        flex-direction: column;
      }
    }

    .imageWrap {
      @include pc {
        flex: 1;
        margin-bottom: 10px;
      }
    }

    .label {
      position: absolute;
      top: -16px;
      left: 8px;
      padding: 2px 11px;
      background: $red;
      color: $white;
      font-size: 10px;
      line-height: 1.5;
      font-weight: 600;
      border-radius: 20px;
      z-index: 1;

      @include pc {
        top: -40px;
        padding: 4px 11px;
        font-size: 13px;

        &::after {
          position: absolute;
          bottom: -3px;
          left: 46%;
          content: "";
          width: 8px;
          height: 8px;
          background: $red;
          rotate: 45deg;
          border-radius: 1px;
          z-index: -1;
          transform: skew(5deg, 5deg);
        }
      }
    }

    .linkText {
      margin-left: 8px;
      color: $blue;
      font-size: 13px;
      line-height: 1.5;
      font-weight: 600;
      text-align: center;

      @include pc {
        flex: 1;
      }
    }
  }
  .controlAreaLinks {
    display: flex;
    gap: 15px;

    @include sp() {
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 11px;
    }

    .control {
      min-width: 260px;
      background: $white;
      border: 1px solid $lightGray;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.02);
      border-radius: 10px;
      display: inline-block;
      padding: 31px 3%;
      height: 90px;
      text-align: center;
      cursor: pointer;
      transition: $hover-transition;

      &:hover {
        opacity: $hover-opacity;
      }

      @include sp() {
        min-width: auto;
        width: 48%;
        padding: 16px;
        background: $white;
        border: 1px solid $lightGray;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
      }

      &.pastCriteria {
        position: relative;
        min-width: 286px;
        border: 3px solid $blue;
        box-shadow: 0px 0px 20px rgb(35, 145, 220, 0.2);
        padding: 21px;

        @include sp() {
          border: 2px solid $blue;
          width: 100%;
          height: 74px;
          padding: 15px;
        }

        p {
          color: $blue;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          letter-spacing: 0.8px;
          text-align: left;

          @include sp() {
            font-size: 14px;
            line-height: 22px;
          }
        }
        span {
          margin: 0 !important;
        }
        .small {
          font-size: 13px;
          line-height: 20px;
          text-align: left;

          @include sp() {
            font-size: 12px;
            line-height: 20px;
          }
        }
        .textOverflow {
          max-width: 225px;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 314px;
        }
      }
      &:before {
        content: "";
        height: 27px;
        width: 27px;
        display: inline-block;
        vertical-align: bottom;
        margin-right: 10px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;

        @include sp() {
          display: block;
          margin: 0 auto 10px auto;
        }
      }
      &.pastCriteria:before {
        display: none;
      }
      &.newArrival:before {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        right: 11px;
        margin-right: 0;
        width: 9px;
        height: 9px;
        flex-shrink: 0;
        border-radius: 9px;
        background: $lightGreen2;
      }
      &.station:before {
        background-image: url("/static/svgs/top/station.svg");
      }
      &.area:before {
        background-image: url("/static/svgs/top/area.svg");
      }

      span {
        font-family: $fontFamily-Top;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.05em;

        @include sp() {
          line-height: 21px;
          font-size: 14px;
        }
      }
    }
  }
  .controlAreaSearch {
    display: flex;
    align-items: center;
    gap: 20px;

    @include sp {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .label {
      flex-shrink: 0;
      font-size: 18px;
      font-weight: 600;

      @include sp {
        font-size: 14px;
      }
    }
  }
  .caution {
    flex: 0 0 auto;
    font-family: $fontFamily-Top;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #969696;
    margin: auto auto 3px 0;
    letter-spacing: 0;

    @include sp() {
      margin: 8px 0 0 auto;
      font-weight: 400;
    }
  }
}

// 保存条件 / 前回条件で探すパーツ
.savedContainer {
  padding: 25px 20px 0px 20px;
  background-color: $superLightGray;

  &Title {
    display: flex;
    margin-bottom: 10px;
    line-height: 16px;

    svg {
      margin-top: auto;
      margin-bottom: auto;
    }

    path {
      fill: $blue;
    }

    p {
      margin-left: 5px;
      color: $blue;
      font-size: 14px;
      font-weight: 500;
    }

    a {
      margin-left: auto;
      color: $blue;
      font-size: 12px;
      font-weight: 500;
    }
  }

  &Box {
    position: relative;
    border-radius: 5px;
    font-size: 13px;
    background-color: white;
    border: 1px solid $lightGray;
    box-shadow: 0px 3px 10px 0px #0000001a;
    a {
      display: block;
      padding: 24px 25px 24px 15px;
    }
    &:after {
      content: "";
      height: 10px;
      width: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(45deg);
    }
  }
}

.carouselItem {
  margin-bottom: 30px;

  @include pc() {
    width: 1140px;
    margin: -30px auto 30px auto;
  }
}
